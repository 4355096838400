window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {


    //window.Popper = require('popper.js').default;
    global.jQuery = window.$ = window.jQuery = require('jquery');
    require('overlayscrollbars');
    require('../../vendor/almasaeed2010/adminlte/dist/js/adminlte');
    require('bootstrap');

    require('../../node_modules/datatables.net/js/jquery.dataTables.js');
    //require('../../node_modules/datatables.net-)
    //require( '../../node_modules/datatables.net-bs4/js/dataTables.bootstrap4.js' );
    require('datatables.net-bs4/js/dataTables.bootstrap4.js');
   // require('datatables.net-buttons/js/dataTables.buttons.js');
    require('datatables.net-buttons-bs4');
    require('datatables.net-responsive-bs4');

    ///require('datatables-pagination-input');
/*    window.$.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': window.$('meta[name="csrf-token"]').attr('content')
        }
    });*/

    require('select2');
    require('jquery-datatables-checkboxes');
    window.Swal = window.swal = require('sweetalert2');

    window.axios = require('axios');

    window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    window.clipboard = require('clipboard');
    require('selectize');
    const bootstrap = require('bootstrap-sass');
    require('bootstrap4-toggle')

    //require('patternfly-bootstrap-treeview');
/*    window.generateSectionDT = generateSectionDT = function generateSectionDT(tableId, sectionId, channel, ajaxUrl) {
        playlists.push($(tableId).DataTable({
            "ajax": {
                "url": ajaxUrl,
                "type": "POST",
                "data": {
                    "channel": channel,
                    "section": sectionId
                }
            },
            'drawCallback': function (settings) {

                var api = this.api();
                api.cells(
                    api.rows(function (idx, data, node) {
                        return localStorage.getItem("selected_playlists").includes(data[0]);
                    }).indexes(),
                    0
                ).checkboxes.select();
            },
            "processing": true,
            "serverSide": true,
            "autowidth": false,
            'columnDefs': [
                {"orderable": false, "targets": [3]},
                {
                    'targets': 0,
                    'checkboxes': {
                        'selectRow': true,
                        'selectAllRender': '<div class="checkbox"><input type="checkbox" ' +
                            'class="dt-checkboxes"><label></label></div>',
                        'selectCallback': function (nodes, selected) {
                            // If "Show all" is not selected
                            // console.log(nodes);

                            var selectedPlaylists = localStorage.getItem("selected_playlists");
                            if (selectedPlaylists !== undefined)
                                $.each(nodes, function (index, node) {
                                    if (selected) {
                                        //console.log(node);
                                    } else {

                                    }
                                });
                        }
                    },
                    'render': function (data, type, row, meta) {

                        //  console.log(data, type, row, meta);
                        if (type === 'display') {
                            data = '<div class="checkbox"><input type="checkbox" data-id="' + data + '" class="dt-checkboxes">' +
                                ' <label></label></div>';
                        }

                        return data;
                    }
                }
            ],
            deferRender: true,
            select: {
                style: 'multi'
            }
        }).on('user-select', function (e, dt, type, cell, originalEvent) {
            if (originalEvent.target.nodeName.toLowerCase() === 'a' || originalEvent.target.nodeName.toLowerCase() === 'button') {
                e.preventDefault();
            }
        }).on('select', function (e, dt, type, indexes) {
            console.log(localStorage.getItem("selected_playlists"));
            var selectedPlaylists = JSON.parse(localStorage.getItem("selected_playlists") || []);
            console.log(selectedPlaylists);
            if (selectedPlaylists !== undefined && type === 'row') {
                var rowId = dt.rows(indexes).data()[0][0];
                if (selectedPlaylists.indexOf(rowId) === -1) {
                    selectedPlaylists.push(rowId)
                }

            }
            localStorage.setItem("selected_playlists", JSON.stringify(selectedPlaylists));
        }).on('deselect', function (e, dt, type, indexes) {
            var selectedPlaylists = JSON.parse(localStorage.getItem("selected_playlists") || []);
            if (selectedPlaylists !== undefined && type === 'row') {
                var rowId = dt.rows(indexes).data()[0][0];
                var index = selectedPlaylists.indexOf(rowId);
                if (index > -1) {
                    selectedPlaylists.splice(index, 1);
                }
            }
            localStorage.setItem("selected_playlists", JSON.stringify(selectedPlaylists));
        }));
    }*/
    window.ajaxIconHanding = function (type, message) {
        switch (type) {
            case 'load':
                swal.fire({
                    title: '',
                    html: '<div class="save_loading"><svg viewBox="0 0 140 140" width="140" height="140"><g class="outline"><path d="m 70 28 a 1 1 0 0 0 0 84 a 1 1 0 0 0 0 -84" stroke="rgba(0,0,0,0.1)" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round"></path></g><g class="circle"><path d="m 70 28 a 1 1 0 0 0 0 84 a 1 1 0 0 0 0 -84" stroke="#71BBFF" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-dashoffset="200" stroke-dasharray="300"></path></g></svg></div><div><h4>' + message + '</h4></div>',
                    showConfirmButton: false,
                    allowOutsideClick: false
                });
                break;
            case false:

                setTimeout(function () {
                    const header = '<div class="swal2-header"><ul class="swal2-progresssteps" style="display: none;"></ul><div class="swal2-icon swal2-error swal2-animate-error-icon" style="display: flex;"><span class="swal2-x-mark"><span class="swal2-x-mark-line-left"></span><span class="swal2-x-mark-line-right"></span></span></div><div class="swal2-icon swal2-question" style="display: none;"><span class="swal2-icon-text">?</span></div><div class="swal2-icon swal2-warning" style="display: none;"><span class="swal2-icon-text">!</span></div><div class="swal2-icon swal2-info" style="display: none;"><span class="swal2-icon-text">i</span></div><div class="swal2-icon swal2-success" style="display: none;"><div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div><span class="swal2-success-line-tip"></span> <span class="swal2-success-line-long"></span><div class="swal2-success-ring"></div> <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div><div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div></div><img class="swal2-image" style="display: none;"><h2 class="swal2-title" id="swal2-title" style="display: flex;"></h2><button type="button" class="swal2-close" style="display: none;">×</button></div>'

                    $(".swal2-header").html(header);

                    $('#swal2-content').html(message);

                }, 1000);
                setTimeout(function () {
                    swal.close(true);
                }, 5000);

                break;
            case true:
                setTimeout(function () {
                    const header = '<div class="swal2-icon swal2-success swal2-animate-success-icon" style="display: flex;"><div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div><span class="swal2-success-line-tip"></span> <span class="swal2-success-line-long"></span><div class="swal2-success-ring"></div> <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div><div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div></div>';
                    $(".swal2-header").html(
                        header
                    );
                    $('#swal2-content').html(message);
                }, 1000);
                setTimeout(function () {
                    swal.close(true);
                }, 5000);
                break;
        }
    }

} catch (e) {
    console.log(e);
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */


/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });
